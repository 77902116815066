import type { SVGProps } from 'react';

export enum IconTypes {
  'arrow45' = 'arrow45',
  'arrowNext' = 'arrowNext',
  'arrowPrevious' = 'arrowPrevious',
  'arrowRight' = 'arrowRight',
  'communal' = 'communal',
  'cross' = 'cross',
  'education' = 'education',
  'eye' = 'eye',
  'eyeClosed' = 'eyeClosed',
  'fines' = 'fines',
  'internet' = 'internet',
  'logo' = 'logo',
  'logoSK' = 'logoSK',
  'mapMark' = 'mapMark',
  'menu' = 'menu',
  'mobile' = 'mobile',
  'resource' = 'resource',
  'settlement' = 'settlement',
  'taxes' = 'taxes',
}

export interface IIconProps extends Omit<SVGProps<SVGSVGElement>, 'style'> {
  icon: IconTypes;
  size?: 16 | 24 | number;
  color?: string;
}
